import { ModalDialog } from './components/modal-dialog';
import { utils } from './utils';
import './components/task-manager.ts';
import './components/form-tracker.ts';
import './components/modal-form.ts';
import './components/activity-menu.ts';
import './components/preserve-current-week-selection.ts';
import './booking/booking-menu.ts';
import './user-activity-watcher.ts';

declare global {
  const url: IUrlOptions;
  const localization: ILocalization;

  interface Window {
    _global: IGlobalInternal;
    globalOptions: IGlobalOptions;
  }
}

const _global: IGlobalInternal = {
  modules: {},
  options: null,
  init: function (appStart: boolean | Element) {
    if (typeof appStart != 'boolean') {
      appStart = false;
    }
    _global.options = window.globalOptions;
    _global.createControls();
    _global.calendar.init();
    _global.draggable.init();
    _global.handlers.init();
    _global.modal.init();

    _global.staffSchedule.init();
    _global.customerActivity.init();
    _global.staffDayActivity.init();
    _global.customerDayActivity.init();
    _global.currentDate.init();
    _global.mvcValidationToBootstrapValidation();
    _global.accordion.init();
    _global.authentication.init();
    _global.substituteStaff.init();

    _global.recurringActivity.init();
    _global.password.init();
    $.each(_global.modules, function (i, module) {
      if (module.init) {
        module.init(window.document.body, appStart);
      }
    });
  },

  bootstrapModuleForElement: function (el) {
    var $el = $(el);
    var moduleName = $el.data('module-name') || $el.children('[data-module-name]').data('module-name');
    if (moduleName) {
      const module: IModule = _global.modules[moduleName];
      if (!module) {
        throw 'Module not found:' + moduleName;
      }
      module.init(el, false);
      (<any>el).module = module;
    }
  },
  tearDownModuleForElement: function (el) {
    var module: IModule = (<any>el).module;
    if (module && module.teardown) {
      module.teardown.call(module);
    }
  },
  afterAjax: function () {
    _global.calendar.afterAjax();
    _global.accordion.afterAjax();
  },
  password: {
    timeout: null,
    init: function () {
      if (!_global.password.timeout && _global.options.CheckPasswordExpiration) {
        _global.password.timeout = setInterval(
          _global.password.checkExpiration,
          1000 * 60 * _global.options.PasswordExpiredTime
        );
      }
      $('#navbar-change-password')
        .off('click')
        .on('click', function () {
          _global.password.changePassword(null);
          return false;
        });
    },
    checkExpiration: function () {
      $.get(url.CheckPasswordExpirationUrl, function (data) {
        if (data && data.Expired) {
          _global.password.changePassword({ isPasswordExpired: true });
        }
      });
    },
    changePassword: function (data) {
      $.get(url.ChangePasswordUrl, data).done(function (html) {
        _global.modal.show(html);
        clearInterval(_global.password.timeout);
        _global.password.timeout = null;
      });
    }
  },
  logout: function () {
    var returnUrl = window.location.pathname + window.location.search + window.location.hash;
    $('#logout-return-url').val(returnUrl);
    $('#logout-form').submit();
  },
  redirectHome: function () {
    window.location.href = '/';
  },
  authentication: <IGlobalAuthentication>{
    minutes: 0,
    timeout: null,
    init: function () {
      $(document).ajaxError(function (event, xhr) {
        if (xhr.status === 401) {
          event.stopImmediatePropagation();
          var $selects = $('.select-2');
          if ($selects.length > 0) {
            $('.select-2').select2('close');
          }
          $('.header-container, .content').remove();
          var $modal = $('.modal');
          $modal.trigger('before-destroy');
          $modal.modal('hide');
          $('#modal-timeout').addClass('modal').modal({ keyboard: false });
          if (_global.authentication.timeout) {
            clearInterval(_global.authentication.timeout);
          }
          $.contextMenu('destroy');
          $(document).trigger('logout');
        }
        if (xhr.status === 403) {
          event.stopImmediatePropagation();
          _global.redirectHome();
        }
      });
      if (_global.options.CheckAuthentication && !_global.authentication.timeout) {
        const check = () => {
          $.ajax(<JQueryAjaxSettingsExtended>{
            method: 'POST',
            url: _global.options.CheckAuthenticationUrl,
            systemRequest: true
          }).done(() => {
            _global.authentication.timeout = window.setTimeout(check, 1000 * 10);
          });
        };
        _global.authentication.timeout = window.setTimeout(check, 1000 * 10);
      }
    }
  },
  handlers: <IGlobalHandlers>{
    init: function () {
      _global.handlers.dayClick();
    },
    setEndDateAsStartDate: function (context) {
      $('#StartDate', context)
        .off('change.onmodal, changeDate')
        .on('change.onmodal, changeDate', function () {
          $('#EndDate', context).val($(this).val());
        });
    },
    dayClick: function () {
      $('body')
        .off('mousedown.dayClick')
        .on('mousedown.dayClick', '#staff-schedule td, #department-activity td, #customer-activity td', function (e) {
          if (e.button == 2) {
            var $this = $(this),
              date = $this.data('date');

            _global.currentDate.highlightDay(date);
          }
        });
    }
  },
  accordion: {
    init: function () {
      $(document).on('click.collapse.data-api', function () {
        if ($('.accordion-group').length == 0) return;

        var config = _global.clientConfiguration.getConfig() || { accordionPages: [] };

        $('.accordion-group .collapse').each(function () {
          if (config.accordionPages.filter(_ => _.id === $(this).attr('id') && _.user === _global.options.UserName).length == 0) {
            config.accordionPages.push({ collapsed: !$(this).hasClass('in'), user: _global.options.UserName, id: $(this).attr('id'), page: window.location.pathname });
          }
          else {
            var index = config.accordionPages.findIndex(_ => _.id === $(this).attr('id') && _.user === _global.options.UserName);
            config.accordionPages[index].collapsed = !$(this).hasClass('in');
          }
        });
        _global.clientConfiguration.setConfig(config);
      });

      _global.accordion.restoreStateFromConfig();
    },
    afterAjax: function () {
      _global.accordion.restoreStateFromConfig();
    },
    restoreStateFromConfig: function () {
      var config = _global.clientConfiguration.getConfig() || { accordionPages: [] };
      var state: any = config.accordionPages || [];
      state = state.filter(_ => _.page === window.location.pathname && _.user === _global.options.UserName);
      
      // TODO: This is not an elegant solution, but it works for now.
      //       It should be refactored to use the element id instead of relying on the index.
      $('.accordion-group .collapse').each(function (i) {
        if (state && state[i]) {
          if (state[i].collapsed || state[i].user != _global.options.UserName) {
            $(this).removeClass('in');
            $(this).parents('.accordion-group').find('.accordion-toggle').addClass('collapsed');
          } else {
            $(this).addClass('in');
            $(this).parents('.accordion-group').find('.accordion-toggle').removeClass('collapsed');
          }
        }
      });
    }
  },
  clientConfiguration: {
    cookie: 'localconfv1',
    setConfig: function (value) {
      var expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);

      document.cookie = [
        encodeURIComponent(_global.clientConfiguration.cookie),
        '=',
        JSON.stringify(value),
        '; expires',
        '=',
        expires.toUTCString(),
        '; path',
        '=',
        '/'
      ].join('');
    },
    getConfig: function () {
      var cookies = document.cookie ? document.cookie.split('; ') : [];

      for (var i = 0, l = cookies.length; i < l; i++) {
        var parts = cookies[i].split('=');
        var name = decodeURI(parts.shift());
        var cookie = parts.join('=');

        if (_global.clientConfiguration.cookie === name) {
          return _global.clientConfiguration.parseCookie(cookie);
        }
      }

      return null;
    },
    updateConfig: function (updateHanlder) {
      if (!updateHanlder || !$.isFunction(updateHanlder)) throw 'Update handler must be a function';

      var config = _global.clientConfiguration.getConfig() || { accordionPages: [] };

      updateHanlder.call(config, config);

      _global.clientConfiguration.setConfig(config);
    },
    readConfig: function (applyHandler) {
      if (!applyHandler || !$.isFunction(applyHandler)) throw 'Update handler must be a function';

      var config = _global.clientConfiguration.getConfig() || {};

      applyHandler.call(config, config);
    },
    parseCookie: function (cookie) {
      if (cookie.indexOf('"') === 0) {
        cookie = cookie.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\');
      }

      try {
        cookie = decodeURIComponent(cookie.replace(/\+/g, ' '));
      } catch (e) {
        return;
      }

      try {
        return JSON.parse(cookie);
      } catch (e) { }
    }
  },
  createTimeControls: function (element?: JQuery) {
    element = element || $(window.document.body);
    const timeRegexp = /^(?<hours>([0-2]?[0-9]|2?[0-3]))[:.]?(?<minutes>[0-5][0-9]?)?$/;
    $("input[data-control='time']", element)
      .off('blur')
      .on('blur', function () {
        var $this = $(this),
          value = $this.val() as string | undefined;
        const timeRegexpResult = timeRegexp.exec(value);
        if (timeRegexpResult) {
          let hours = timeRegexpResult.groups['hours'];
          let minutes = timeRegexpResult.groups['minutes'];
          if (hours) {
            if (hours.length == 1) {
              hours = '0' + hours;
            }
            if (!minutes) {
              minutes = '00';
            }
            if (minutes.length == 1) {
              minutes = minutes + '0';
            }
            const formatted = [hours, minutes].join(':');
            $this.val(formatted);
          }
        }
      });
  },
  createControls: function () {
    $("input[data-control='date']").datetimepicker(<any>{
      language: 'sv',
      pickDate: true,
      pickTime: false,
      showToday: true
    });

    _global.createTimeControls();

    var isNumberKey = function (e) {
      var key = e.charCode || e.keyCode || 0;
      return (
        key === 8 ||
        key === 9 ||
        key === 13 ||
        key === 46 ||
        key === 110 ||
        key === 190 ||
        (key >= 35 && key <= 40) ||
        (key >= 48 && key <= 57) ||
        (key >= 96 && key <= 105)
      );
    };

    $("input[data-control='spinner']")
      .spinner({
        min: 1,
        max: 52,
        create: function () {
          var $spinner = $(this);
          var $year = $spinner.parent().siblings("input[data-control='year']");
          $spinner
            .siblings('.ui-spinner-up')
            .off('.spinner-buttons')
            .on('mousedown.spinner-buttons', function (e) {
              if ($spinner.spinner('value') >= 53) {
                $year.val(parseInt($year.val() as string) + 1);
                $spinner.spinner('value', 1);
                e.preventDefault();
                e.stopImmediatePropagation();
              }
            });
          $spinner
            .siblings('.ui-spinner-down')
            .off('.spinner-buttons')
            .on('mousedown.spinner-buttons', function (e) {
              if ($spinner.spinner('value') <= 1) {
                $year.val(parseInt($year.val() as string) - 1);
                $spinner.spinner('value', 53);
                e.preventDefault();
                e.stopImmediatePropagation();
              }
            });
        }
      })
      .off('.data-control-events')
      .on('keydown.data-control-events', function (e) {
        return isNumberKey(e);
      })
      .on('keyup.data-control-events', function () {
        var that: { value: any } = <any>this;
        if (parseInt(that.value) <= 0 || isNaN(that.value)) {
          that.value = 1;
          return;
        } else if (parseInt(that.value) >= 53) {
          that.value = 52;
          return;
        }
      });
    $("input[data-control='year']")
      .off('.data-control-events')
      .on('keydown.data-control-events', function (e) {
        return isNumberKey(e);
      });

    (<any>$.support).transition = false; //$.support.transition wrong for ff.
    $.contextMenu('html5', true);

    $(document)
      .off('ajaxSend')
      .on('ajaxSend', function (event, jqxhr, settings) {
        if (settings.systemRequest) {
          return;
        }
        $('#loading-indicator').show();
      })
      .off('ajaxComplete')
      .on('ajaxComplete', function (event, jqxhr, settings) {
        if (settings.systemRequest) {
          return;
        }
        $('#loading-indicator').hide();
      })
      .off('ajaxError')
      .on('ajaxError', function (event, jqxhr, settings, thrownError) {
        var message;
        if (settings.systemRequest) {
          return;
        }
        if (jqxhr.status === 0) {
          return;
        }
        if (jqxhr.statusText === 'abort') {
          return;
        }
        if (jqxhr.status === 401 || jqxhr.status === 403) {
          return;
        }
        if (jqxhr.responseJSON && jqxhr.responseJSON.Message) {
          message = jqxhr.responseJSON.Message;
        } else {
          message = localization.default_ajax_error_message;
        }
        ModalDialog.show(message);
      });
  },
  // Process layout to convert MVC validation errors to structure of bootstrap validation.
  mvcValidationToBootstrapValidation: function () {
    $('.control-group').removeClass('error');
    $('.field-validation-error').addClass('help-inline').closest('.control-group').addClass('error');
  },
  reloadPageContent: function () {
    $('form.calendar-form').submit();
  },
  isNormalInteger: function (str) {
    return /^\+?(0|[1-9]\d*)$/.test(str);
  },
  currentDate: {
    clickableHeaderSelector: '.fc-view .header-navigation-allowed th.fc-widget-header:not(.fc-first,.fc-last)',

    init: function () {
      _global.currentDate.handleCalendarDayClick();
      _global.currentDate.restoreHighlightedDay();
    },
    afterAjax: function () {
      _global.currentDate.restoreHighlightedDay();
    },
    removeHighlightedIndex: function () {
      _global.clientConfiguration.updateConfig(function (config) {
        config.highlighedIndex = null;
      });
    },
    storeHighlightedIndex: function () {
      _global.clientConfiguration.updateConfig(function (config) {
        const index = $(_global.currentDate.clickableHeaderSelector).filter('.fc-state-highlight').index() - 1;
        config.highlighedIndex = { [_global.options.SessionToken]: index };
      });
    },
    restoreHighlightedDay: function () {
      $('#CurrentDate').trigger('change');
      _global.clientConfiguration.readConfig(function (config) {
        var highlighedDay = config.highlighedIndex ? config.highlighedIndex[_global.options.SessionToken] : null;
        if (typeof highlighedDay === 'number') {
          var td = $(_global.currentDate.clickableHeaderSelector).eq(highlighedDay);
          _global.currentDate.highlightDay($(td).data('date'));
        }
      });
    },
    handleCalendarDayClick: function () {
      $(_global.currentDate.clickableHeaderSelector)
        .off('click')
        .on('click', function () {
          _global.currentDate.highlightDay($(this).data('date'));

          var highlightedDay = $(this).index() - 1;
          _global.clientConfiguration.updateConfig(function (config) {
            config.highlighedIndex = { [_global.options.SessionToken]: highlightedDay };
          });
        });
    },
    highlightDay: function (date) {
      if (!date) return;

      $('th[data-date]').removeClass('fc-state-highlight');
      $('td[data-date]').removeClass('fc-state-highlight');

      var $calHeader = $('th[data-date=' + date + ']');
      $calHeader.addClass('fc-state-highlight');
      $('td[data-date=' + date + ']').addClass('fc-state-highlight');

      $('#CurrentDate').val(date);
      $('#CurrentDate').trigger('change');

      _global.currentDate.reloadDayActivity($('#staff-day-activity-content'), date, _global.draggable.staffDayActivity);
      _global.currentDate.reloadDayActivity(
        $('#customer-day-activity-content'),
        date,
        _global.draggable.customerDayActivity
      );

      $('#CurrentWeek').val($calHeader.data('week'));
      $('#CurrentYear').val(new Date(date).getFullYear());
    },
    reloadDayActivity: function ($container: JQuery, date, callback) {
      if ($container.length == 0) {
        return;
      }
      var url = $('table', $container).data('load-url'),
        currentDepartment = $('#CurrentDepartment').val();

      $.ajax({
        type: 'POST',
        url: url,
        dataType: 'html',
        data: { currentDepartment: currentDepartment, currentDate: date },
        traditional: true,
        success: function (data) {
          $container.html(data);
          _global.draggable.init();
          if (callback) callback();
        }
      });
    }
  },
  draggable: <IGlobalDraggable>{
    option: function () {
      return {
        revert: 100,
        forcePlaceholderSize: true,
        placeholder: 'ui-sortable-placeholder'
      };
    },
    init: function () {
      _global.draggable.customerActivity();
      _global.draggable.depertmentActivity();
      _global.draggable.staffDayActivity();
      _global.draggable.customerDayActivity();
    },
    customerActivity: function () {
      $("#customer-activity ul[data-control='sortable']").sortable(_global.draggable.getVerticalDirectionOption());
    },
    depertmentActivity: function () {
      $("#department-activity ul[data-control='sortable']").sortable(
        _global.draggable.getVerticalAndHorizontalDirectionOption()
      );
    },
    staffDayActivity: function () {
      $("#staff-day-activity ul[data-control='sortable']").sortable(_global.draggable.getVerticalDirectionOption());
    },
    customerDayActivity: function () {
      $("#customer-day-activity ul[data-control='sortable']").sortable(_global.draggable.getVerticalDirectionOption());
    },
    getVerticalDirectionOption: function () {
      var option = _global.draggable.option();
      $.extend(option, {
        axis: 'y',
        containment: 'parent',
        update: function (event) {
          _global.draggable.sendRequest(event);
        }
      });
      return option;
    },
    getVerticalAndHorizontalDirectionOption: function () {
      var option = _global.draggable.option();
      $.extend(option, {
        connectWith: '#department-activity ul',
        update: function (event, ui) {
          if (_global.draggable.isInCurrentList(event, ui)) {
            _global.draggable.sendRequest(event);
          }
        }
      });
      return option;
    },
    sendRequest: function (event) {
      var $el = $(event.target),
        url = $el.parents('table').data('sort-url'),
        date = $el.parents('td').data('date'),
        customerId = $el.parents('td').data('customer-id'),
        employeeId = $el.parents('td').data('employee-id'),
        keys = [];

      $('div.fc-event', event.target).each(function () {
        keys.push($(this).data('id'));
      });

      if (!date) date = $('#CurrentDate').val();
      if (keys.length === 0) {
        return null;
      }
      return $.ajax({
        type: 'POST',
        url: url,
        dataType: 'json',
        data: { activities: keys, date: date, customerId: customerId, employeeId: employeeId },
        traditional: true
      });
    },
    //we need this, for handling cahnge event only once. Not to handle it twice, in source and target list.
    isInCurrentList: function (event, ui) {
      return ui.item.parents('ul').get(0) == $(event.target).get(0);
    }
  },
  calendar: {
    init: function () {
      $('#planning-container')
        .off('click')
        .on('click', '.fc-button-prev, .fc-button-next', function () {
          var navigateTo = $(this).data('navigate-to');
          if (navigateTo == 'nextday' || navigateTo == 'prevday') {
            _global.clientConfiguration.updateConfig(function (settings: IConfig) {
              //TODO bug
              settings.highlighedIndex = null;
            });
          }

          _global.calendar.sendRequest($(this).data('navigate-to'));
        });
    },
    sendRequest: function (value) {
      var form = $('.calendar-form');
      form.append("<input type='hidden' name='event' value='" + value + "' />");
      form.submit();
    },
    afterAjax: function () {
      _global.init(false);
      $(document).trigger('update-links');
    }
  },
  modal: <IGlobalModal>{
    init: function () {
      //$('body')
      //	.off('wheel.modal mousewheel.modal')
      //	.on('wheel.modal mousewheel.modal', function (e) {
      //	return $(e.target).parents(".modal").length > 0 || !$('body').hasClass("modal-open");
      //});
      $(document)
        .off('click.closemodal')
        .on('click.closemodal', '.js-modal-cancel', function () {
          var modal = $(this).closest('.modal');
          modal.trigger('cancel');
          modal.modal('hide');

          return false;
        })
        .off('shown.bs.modal')
        .on('shown.bs.modal', function (e) {
          _global.bootstrapModuleForElement(e.target);
          var $modal = $(e.target);
          var field = $modal.find('[autofocus]');
          setTimeout(function () {
            field.focus();
          }, 0);
        })
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', function (e) {
          _global.tearDownModuleForElement(e.target);
        });
    },
    show: function (html, leaveAllModals) {
      html = html.trim();

      var onshowDfd = $.Deferred();
      if (leaveAllModals !== true) {
        var $modal = $('body > .modal, body > .modal-backdrop');
        $modal.each(function () {
          _global.tearDownModuleForElement(this);
        });
        $modal.remove();
      }
      var modalContent = $(html),
        id = modalContent.attr('id');

      if (!id) {
        id = 'id' + utils.guid();
        modalContent.attr('id', id);
      }

      var modalSelector = '#' + id;

      $('body').append(modalContent);
      var $el = $(modalSelector);

      $el.on('show.bs.modal', function () {
        if ($el.position().top < $(document).scrollTop() && $el.css('position') !== 'fixed') {
          $el.css('top', $(document).scrollTop() + 'px');
        }
        $el.find('.js-autofocus').focus();
        onshowDfd.resolve();
      });

      $el.modal();
      $el.removeClass('hide');
      $el.draggable({
        handle: '.modal-header'
      });

      var oncloseDfd = $.Deferred();

      $el.on('hide.bs.modal', function () {
        oncloseDfd.resolve();
      });

      var closeModalFunction = function () {
        $el.modal('hide');
        $el.next('.modal-backdrop').remove();
        oncloseDfd.resolve();
      };

      _global.init(false);

      return {
        close: closeModalFunction,
        openPromise: onshowDfd.promise(),
        closePromise: oncloseDfd.promise(),
        $el: $el
      };
    }
  },
  staffSchedule: <IGlobalStaffSchedule>{
    init: function () { },
    openEditForm: function (url) {
      $.post(url).done(function (html) {
        var modal = _global.modal.show(html);
        modal.openPromise.then(function () {
          modal.$el.trackDialogForm({ dialogUrl: url });
          _global.handlers.setEndDateAsStartDate();
        });
      });
    },
    openDeleteForm: function (url) {
      $.post(url).done(function (html) {
        var modal = _global.modal.show(html);
        modal.openPromise.then(function () {
          modal.$el.trackDialogForm({ dialogUrl: url });
        });
      });
    },
    handleEditMenuItem: function (e, opts) {
      var url = $(opts.$trigger).data('staff-schedule-edit');
      if (url) {
        _global.staffSchedule.openEditForm(url);
        return false;
      }
      return true;
    },
    handleCreateMenuItem: function (e, opts) {
      var url = $(opts.$trigger).data('staff-schedule-create');
      if (!url) url = $(opts.$trigger).parents('td').data('staff-schedule-create');
      if (url) _global.staffSchedule.openEditForm(url);
    },
    handleDeleteMenuItem: function (e, opts) {
      var url = $(opts.$trigger).data('staff-schedule-delete');
      if (!url) url = $(opts.$trigger).parents('td').data('staff-schedule-delete');
      if (url) _global.staffSchedule.openEditForm(url);
    }
  },
  customerActivity: {
    init: function () {
      $(document)
        .off('click.customerActivityEdit')
        .on('click.customerActivityEdit', '#customer-activity *[data-edit-activity-url]', function () {
          var url = $(this).data('edit-activity-url');
          if (url) {
            _global.openEditForm(url);
            return false;
          }
          return true;
        });

      $(document)
        .off('dblclick.customerActivityCreate')
        .on('dblclick.customerActivityCreate', '#customer-activity *[data-create-activity-url]', function () {
          var url = $(this).data('create-activity-url');
          if (url) {
            _global.openEditForm(url);
            return false;
          }
          return true;
        });
    }
  },
  customerDayActivity: {
    init: function () {
      $(document)
        .off('click.customerActivityEdit')
        .on('click.customerActivityEdit', '#customer-day-activity *[data-edit-activity-url]', function () {
          var url = $(this).data('edit-activity-url');
          if (url) {
            _global.openEditForm(url);
            return false;
          }
          return true;
        });

      $(document)
        .off('dblclick.customerActivityCreate')
        .on('dblclick.customerActivityCreate', '#customer-activity *[data-create-activity-url]', function () {
          var url = $(this).data('create-activity-url');
          if (url) {
            _global.openEditForm(url);
            return false;
          }
          return true;
        });
    }
  },
  staffDayActivity: {
    init: function () {
      $(document)
        .off('click.staffDayActivityEdit')
        .on('click.staffDayActivityEdit', '#staff-day-activity *[data-edit-activity-url]', function () {
          var url = $(this).data('edit-activity-url');
          if (url) {
            _global.openEditForm(url);
            return false;
          }
          return true;
        });

      $(document)
        .off('dblclick.staffDayActivityCreate')
        .on('dblclick.staffDayActivityCreate', '#staff-day-activity *[data-create-activity-url]', function () {
          var url = $(this).data('create-activity-url');
          if (url) {
            _global.openEditForm(url);
            return false;
          }
          return true;
        });
    }
  },
  openEditForm: function (url, data, invocationContext) {
    //TODO rework it all with promises
    var dfd = $.Deferred();
    $.post(url, data)
      .done(function (html) {
        var modal = _global.modal.show(html);
        modal.openPromise.then(function () {
          modal.$el[0].invocationContext = invocationContext;
          modal.$el.trackDialogForm({ dialogUrl: url });
          modal.$el.one('cancel.callback-tracker', function () {
            dfd.reject();
          });
          modal.$el.one('success.callback-tracker', function () {
            dfd.resolve();
          });
          _global.handlers.setEndDateAsStartDate();
        });
      })
      .fail(function () {
        dfd.reject();
      });
    return dfd.promise();
  },
  activity: <IGlobalActivity>{
    checkDuplicatedTime: function () {
      if (($('#CheckTimeDuplication').val() as any) === true) {
        if (($('#IsTimeDuplicated').val() as any) === true) {
          $('#modal-duplicate-time').show();
        }
      }

      $('#modal-duplicate-time')
        .off('click', 'js-modal-ok')
        .on('click', 'js-modal-ok', function () { });
    },
    handleEditMenuItem: function (e, opts) {
      var url = $(opts.$trigger).data('edit-activity-url');
      if (url) {
        _global.openEditForm(url);
        return false;
      }
      return true;
    },
    handleCreateMenuItem: function (e, opts) {
      var url = $(opts.$trigger).data('create-activity-url');
      if (!url) url = $(opts.$trigger).parents('td').data('create-activity-url');
      if (url) _global.openEditForm(url);
    },
    handleDeleteMenuItem: function (e, opts) {
      var url = $(opts.$trigger).data('delete-activity-url');
      if (url && confirm(localization.menu.DeletingActivityConfirmation))
        $.post(url).always(function () {
          _global.reloadPageContent();
        });
    }
  },
  substituteStaff: {
    init: function () {
      var adjustUsersDropDownVisibility = function () {
        var shouldBeDisabled = $("input:radio[name='ReplaceType']:checked").val() != 'Staff';
        var currentDepartments = $('#user-current-department'),
          allDepartments = $('#user-all-departments');
        if ($('#AllDepartments').prop('checked')) {
          currentDepartments.hide().prop('disabled', true);
          allDepartments.show().prop('disabled', shouldBeDisabled);
        } else {
          currentDepartments.show().prop('disabled', shouldBeDisabled);
          allDepartments.hide().prop('disabled', true);
        }
      };
      adjustUsersDropDownVisibility();
      $(document)
        .off('click', '.add-substitutor')
        .on('click', '.add-substitutor', function () {
          var $el = $(this),
            url = $el.data('url'),
            f = $el.closest('form'),
            d = f.serialize();

          $.post(url, d, function (formHtml) {
            var modal = _global.modal.show(formHtml);
            if (!formHtml) {
              modal.close();
              _global.reloadPageContent();
            } else {
              var $cancelButton = modal.$el.find('.js-modal-cancel');
              var $buttons = modal.$el.find('.js-remove-substitutor, .add-substitutor');
              modal.openPromise.then(function () {
                modal.$el.trackDialogForm({ dialogUrl: url });
                $cancelButton.on('click.dialog-interceptor', function (e) {
                  //TODO move to module
                  e.stopImmediatePropagation();
                  modal.$el.trigger('cancel');
                  var url = $cancelButton.data('url');
                  _global.staffSchedule.openEditForm(url);
                });
                $buttons.on('click.dialog-interceptor', function () {
                  modal.$el.trigger('cancel');
                });
              });
              modal.closePromise.then(function () {
                $cancelButton.off('..dialog-interceptor');
                $buttons.off('..dialog-interceptor');
              });
            }
          });
        })
        .off('click', '.js-remove-substitutor')
        .on('click', '.js-remove-substitutor', function () {
          var $el = $(this),
            url = $el.data('url'),
            f = $el.closest('form'),
            d = f.serialize();

          $.post(url, d, function (formHtml) {
            var modal = _global.modal.show(formHtml);
            if (!formHtml) {
              modal.close();
              _global.reloadPageContent();
            } else {
              var $cancelButton = modal.$el.find('.js-modal-cancel');
              var $buttons = modal.$el.find('.js-remove-substitutor, .add-substitutor');
              modal.openPromise.then(function () {
                modal.$el.trackDialogForm({ dialogUrl: url });
                $cancelButton.on('click.dialog-interceptor', function (e) {
                  //TODO move to module
                  e.stopImmediatePropagation();
                  modal.$el.trigger('cancel');
                  $cancelButton.off('.dialog-interceptor');
                  var url = $cancelButton.data('url');
                  _global.staffSchedule.openEditForm(url);
                });
                $buttons.on('click.dialog-interceptor', function () {
                  modal.$el.trigger('cancel');
                });
              });
              modal.closePromise.then(function () {
                $cancelButton.off('..dialog-interceptor');
                $buttons.off('..dialog-interceptor');
              });
            }
          });
        })
        .off('change', '#staffSubstituteForm input:checkbox')
        .on('change', '#staffSubstituteForm input:checkbox', function () {
          adjustUsersDropDownVisibility();
        })
        .off('click', '#staffSubstituteForm input:radio')
        .on('click', '#staffSubstituteForm input:radio', function () {
          $('#staffSubstituteForm #EmployeeId').prop('disabled', true);
          $(this).parents('.form-group').find('select').prop('disabled', false);
          adjustUsersDropDownVisibility();
        });
    }
  },
  recurringActivity: {
    init: function () {
      $('#chckbx-all-days')
        .off('click')
        .on('click', function () {
          $('.js-days').click();
        });
    }
  }
};
const instance = (window._global = window._global || _global);

export { instance as _global };
