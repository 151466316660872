import { ModalDialog } from './components/modal-dialog';
import { _global } from './global';
require('./components/data-grid.ts');
require('./components/editable-cell.ts');
require('./employees.ts');

declare global {
	interface IUsersModule extends IModule {
		link: (e: Event, isLinked: boolean, grid: IDataGrid) => void;
		remove: (grid: IDataGrid) => void;
		restore: (grid: IDataGrid) => void;
		edit: (grid: IDataGrid) => void;
		editLink: (grid: IDataGrid) => void;
		reload: () => void;
		grid: IDataGrid;
	}

	interface IGlobalInternal {
		users?: IUsersModule;
	}
}

var emailValidator = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

function getCompanyRoles() {
	return [
		{ id: '', text: localization.Employee_Column_Role_NoRole },
		{ id: 9, text: localization.User_Role_Administrator },
		{ id: 11, text: localization.User_Role_Chief },
		{ id: 12, text: localization.User_Role_DistrictNurse }
	];
}

function getCustomerRoles() {
	return [
		{ id: '', text: localization.Employee_Column_Role_NoRole },
		{ id: 8, text: localization.User_Role_Relative },
		{ id: 14, text: localization.User_Role_Ansvarsperson },
		{ id: 16, text: localization.User_Role_Supervisory }
	];
}

function getDepartmentRoles() {
	return [
		{ id: '', text: localization.Employee_Column_Role_NoRole },
		{ id: 4, text: localization.User_Role_TeamLeader },
		{ id: 5, text: localization.User_Role_Personnel },
		{ id: 15, text: localization.User_Role_DepartmentAdmin },
		{ id: 17, text: localization.User_Role_Substitutor }
	];
}

_global.reloadPageContent = function (grid: IDataGrid) {
	(grid || _global.users.grid).dataGrid('refresh');
};

var cellsOptions = {
	getFieldSource: function (field) {
		if (field === 'isactive' || field === 'issubstitute' || field === 'isdeleted') {
			return url.TrueFalseOptionsUrl;
		} else if (field === 'companyroleid') {
			return getCompanyRoles();
		} else if (field === 'customerroleid') {
			return getCustomerRoles();
		} else if (field === 'departmentroleid') {
			return getDepartmentRoles();
		}
		return null;
	},
	validateField: function (field, value) {
		if (field === 'password') {
			if (!value) {
				return localization.User_PasswordIsRequired;
			} else if (value.length < 6) {
				return localization.User_PasswordIsTooShort;
			} else if (value.length > 50) {
				return localization.User_PasswordIsTooLong;
			}
		}
		if (field === 'username') {
			if (!value) {
				return localization.User_UsernameIsRequired;
			} else if (value.length < 6) {
				return localization.User_UsernameIsTooLong;
			} else if (value.length > 50) {
				return localization.User_UsernameIsTooShort;
			}
		}
		if (field === 'firstname') {
			if (!value) {
				return localization.User_FirstnameIsRequired;
			} else if (value.length > 100) {
				return localization.User_FirstnameIsTooLong;
			}
		}
		if (field === 'lastname') {
			if (!value) {
				return localization.User_LastnameIsRequired;
			} else if (value.length > 100) {
				return localization.User_LastnameIsTooLong;
			}
		}
		if (field === 'email') {
			if (!value) {
				return null;
			} else if (value.length > 150) {
				return localization.User_EmailIsTooLong;
			} else if (!emailValidator.test(value)) {
				return localization.User_WrongEmailFormat;
			}
		}
		if (field === 'phoneprivate') {
			if (value.length > 50) {
				return localization.User_PhonePrivateIsTooLong;
			}
		}
		if (field === 'phonework') {
			if (value.length > 50) {
				return localization.User_PhoneWorkIsTooLong;
			}
		}
		if (field === 'mobile') {
			if (value.length > 50) {
				return localization.User_MobileIsTooLong;
			}
		}
		if (field === 'ssn') {
			if (value.length > 50) {
				return localization.User_SSNIsTooLong;
			}
		}
		if (field === 'employeeno') {
			if (value.length > 50) {
				return localization.User_EmployeeNoIsTooLong;
			}
		}
		if (field === 'displayname') {
			if (value.length > 150) {
				return localization.User_DisplayNameIsTooLong;
			}
		}
		return null;
	},
	displayField: function (field, value) {
		if (field === 'password') {
			return '*****';
		} else if (field === 'isactive' || field === 'issubstitute' || field === 'isdeleted') {
			if (value === 'True') {
				$(this).text(localization.options.Yes);
			} else if (value === 'False') {
				$(this).text(localization.options.No);
			} else {
				$(this).text('...');
			}
		} else if (field === 'companyroleid') {
			var companyRoles = getCompanyRoles();
			var companyRole = $.grep(companyRoles, function (role) {
				return role.id === Number(value);
			});
			if (!companyRole || companyRole.length === 0) {
				$(this).text(localization.Employee_Column_Role_NoRole);
			} else {
				$(this).text(companyRole[0].text);
			}
		} else if (field === 'customerroleid') {
			var customerRoles = getCustomerRoles();
			var customerRole = $.grep(customerRoles, function (role) {
				return role.id === Number(value);
			});
			if (!customerRole || customerRole.length === 0) {
				$(this).text(localization.Employee_Column_Role_NoRole);
			} else {
				$(this).text(customerRole[0].text);
			}
		} else if (field === 'departmentroleid') {
			var departmentRoles = getDepartmentRoles();
			var departmentRole = $.grep(departmentRoles, function (role) {
				return role.id === Number(value);
			});
			if (!departmentRole || departmentRole.length === 0) {
				$(this).text(localization.Employee_Column_Role_NoRole);
			} else {
				$(this).text(departmentRole[0].text);
			}
		} else {
			$(this).text(value);
		}
	},
	getEmptytext: function (field) {
		if (field === 'password') {
			return '*****';
		}
		return '...';
	}
};

var users: IUsersModule = <IUsersModule>{
	grid: null,
	init($el: any, isAppStart: boolean) {
		const $table: JQuery = $el !== window.document.body ? $el : $('#employees');
		var id = $table.attr('id');
		var $toolbar = $('#' + id + '-toolbar');
		var deferred = $.Deferred();
		var grid: IDataGrid = (this.grid = $table.dataGrid({
			$toolbar: $toolbar,
			addTitle: localization.DataGrid_NewButtonText,
			addText: localization.DataGrid_NewButtonText,
			addIcon: '',
			persistanceKey: 'users-grid-' + _global.options.UserName,
			pageList: [25, 50, 100, localization.DataGrid_Paging_All],
			classes: 'data-grid-readonly',
			refreshTitle: localization.DataGrid_Refresh,
			editIcon: '',
			editText:
				'<span class="swith-to-all">' +
				localization.Users_Show_All +
				'</span><span class="swith-to-selected">' +
				localization.Users_Show_Selected +
				'</span>',
			loadingMssage: localization.DataGrid_LoadingMessage,
			formatRecordsPerPage: function (pageNumber) {
				return (<any>localization.DataGrid_Paging_RecordsPerPage).format(pageNumber);
			},
			formatShowingRows: function (pageFrom, pageTo, totalRows) {
				return (<any>localization.DataGrid_Paging_ShowingRows).format(pageFrom, pageTo, totalRows);
			},
			formatAllRows: function () {
				return localization.DataGrid_Paging_All;
			},
			formatNoMatches: function () {
				return localization.DataGrid_NoRecordsFound;
			},
			onReady: function () {
				$table.find("[data-control='editable-cell']").editableCell(cellsOptions);
				$table.on('click', '[data-role="remove-employee-row"]', function () {
					_global.users.remove.apply(this, [grid]);
				});
				$table.on('click', '[data-role="restore-employee-row"]', function () {
					_global.users.restore.apply(this, [grid]);
				});
				$table.on('click', '[data-role="edit-employee-row"]', function () {
					_global.users.edit.apply(this, [grid]);
				});
				$table.on('click', '[data-role="edit-link-employee-row"]', function () {
					_global.users.editLink.apply(this, [grid]);
				});
				$table.on('change', '[data-control="toggle-checkbox"]', function (e) {
					var linked = $(this).prop('checked');
					return _global.users.link.apply(this, [e, linked, grid]);
				});
				var $tabs = $toolbar.find('li');
				$tabs.on('click', function () {
					$tabs.removeClass('active');
					$(this).addClass('active');
					grid.dataGrid('refresh');
				});
				$table.addClass('ready');
				deferred.resolve();
				return true;
			},
			onLoadSuccess: function (response) {
				if (response.Meta) {
					var columns = response.Meta.columns;
					if (columns) {
						var $header = $table.find("[data-role='report-row']:first-child");
						$.each(columns, function (key: string, value: { title: string }) {
							$header.find("[data-role='report-cell'][data-name='" + key.toLowerCase() + "']").text(value.title);
						});
					}
				}
			},
			onAdd: function () {
				var url = $table.data('create-url');
				_global.openEditForm(url, null, grid).done(function () {
					$toolbar.find('#All a').tab('show');
					grid.dataGrid('resetOptions');
				});
			},
			onBeforeRequest: function (data) {
				var $viewMode = $toolbar.find('li.active');
				data.tableMode = grid.dataGrid('isEditMode') ? 'editing' : 'normal';
				data.viewMode = $viewMode.attr('id');
			},
			onBeforeTableUpdate: function () {
				$table.find("[data-control='editable-cell']").editableCell('destroy');
			},
			onAfterTableUpdate: function () {
				//setTimeout(function () {
				//    .editableCell();
				//});
				//var $cells = $table.find("[data-control='editable-cell']").editableCell();;
				$table.find("[data-control='editable-cell']").editableCell(cellsOptions);
			}
		}));
		grid.toolbar = $toolbar;
		return deferred.promise;
	},
	link(e, isLinked, grid) {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var scope = $row.attr('data-scope');
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		var linkUrl = $table.data('link-url');
		if (scope === 'deparment') {
			if (!linkUrl) {
				throw 'link-url is missing';
			}
			$.post(linkUrl, { id: id, isLinked: isLinked })
				.done(function () {
					(<any>$row.find("[data-control='editable-cell'][data-name='customerroleid']")).editable(
						'option',
						'disabled',
						!isLinked
					);
					(<any>$row.find("[data-control='editable-cell'][data-name='departmentroleid']")).editable(
						'option',
						'disabled',
						!isLinked
					);
					$row.attr('data-checked', <any>isLinked);
					if (!isLinked) {
						$row.find("[data-name='genericcolumn'] [data-role='cell-value']").empty();
					}
				})
				.fail(() => {
					if (isLinked) {
						$(e.target).removeProp('checked');
					} else {
						$(e.target).prop('checked', 'true');
					}
				});
		} else {
			if (!isLinked) {
				if (!linkUrl) {
					throw 'link-url is missing';
				}
				$.post(linkUrl, { id: id, isLinked: isLinked })
					.done(function () {
						(<any>$row.find("[data-control='editable-cell'][data-name='customerroleid']")).editable(
							'option',
							'disabled',
							!isLinked
						);
						(<any>$row.find("[data-control='editable-cell'][data-name='departmentroleid']")).editable(
							'option',
							'disabled',
							!isLinked
						);
						$row.attr('data-checked', <any>isLinked);
						if (!isLinked) {
							$row.find("[data-name='genericcolumn'] [data-role='cell-value']").empty();
						}
					})
					.fail(() => {
						if (isLinked) {
							$(e.target).removeProp('checked');
						} else {
							$(e.target).prop('checked', 'true');
						}
					});
			} else {
				var editLinkUrl = $table.data('edit-link-url');
				ModalDialog.form(editLinkUrl, { id: id })
					.then(() => {
						users.grid.dataGrid('refresh');
					})
					.catch((rejection: IFormRejection) => {
						$(e.target).removeProp('checked');
						if (rejection.isCancelled) {
							return;
						} else if (rejection.message) {
							ModalDialog.show(rejection.message);
						}
					});
				e.preventDefault();
				return false;
			}
		}
	},
	remove(grid) {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('remove-url');
		if (!url) {
			throw 'remove-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		ModalDialog.form(url, { id: id })
			.then(() => {
				var $mode = grid.toolbar.find('li.active');
				if ($mode.attr('id') === 'All') {
					$row.attr('data-is-removed', 'true');
				} else {
					grid.dataGrid('refresh');
				}
			})
			.catch((rejection: IFormRejection) => {
				if (rejection.isCancelled) {
					return;
				} else if (rejection.message) {
					ModalDialog.show(rejection.message);
				}
			});
	},
	restore(grid) {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('restore-url');
		if (!url) {
			throw 'restore-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		$.post(url, { id: id }).done(function () {
			var $mode = grid.toolbar.find('li.active');
			if ($mode.attr('id') === 'All') {
				$row.attr('data-is-removed', 'false');
			} else {
				grid.dataGrid('refresh');
			}
		});
	},
	edit(grid) {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('edit-url');
		if (!url) {
			throw 'edit-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		_global.openEditForm(url, { userId: id }, grid);
		//$.post(url, { id: id }).success(function () {
		//    grid.dataGrid('refresh');
		//});
	},
	editLink(grid) {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('edit-link-url');
		if (!url) {
			throw 'edit-link-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		_global.openEditForm(url, { id: id }, grid);
	},
	reload() {
		_global.users.grid.dataGrid('refresh');
	}
};

_global.modules.Users = users;
_global.users = users;
